<template>
  <a-list
    size='large'
    rowKey='id'
    :loading='loading'
    itemLayout='vertical'
    :dataSource='data'
  >
    <a-list-item :key='item.id' slot='renderItem' slot-scope='item'>
      <template slot='actions'>
      </template>
      <a-list-item-meta>
        <a slot='title' href='https://vue.ant.design/'>{{ item.title }}</a>
        <template slot='description'>
          <span>
            <a-tag>Ant Design</a-tag>
            <a-tag>设计语言</a-tag>
            <a-tag>蚂蚁金服</a-tag>
          </span>
        </template>
      </a-list-item-meta>
      <article-list-content :description='item.description' :owner='item.owner' :avatar='item.avatar' :href='item.href'
                            :updateAt='item.updatedAt' />
    </a-list-item>
    <div slot='footer' v-if='data.length > 0' style='text-align: center; margin-top: 16px;'>
      <a-button @click='loadMore' :loading='loadingMore'>加载更多</a-button>
    </div>
  </a-list>
</template>

<script>
import { ArticleListContent } from '@/components'

export default {
  name: 'Article',
  components: {
    ArticleListContent
  },
  data() {
    return {
      loading: true,
      loadingMore: false,
      data: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      for (let i = 0; i < 11; i++) {
        this.data.push({
          id: i,
          title: '好食材',
          avatar: '',
          description: 'vue3',
          star: 20,
          like: 20,
          message: 20,
          owner: 'hhp',
          href: '',
          updatedAt: '2020-07-22 23:11:33'
        })
      }
      this.loading = false
    },
    loadMore() {
      this.loadingMore = true
    }
  }
}
</script>

<style scoped>

</style>
